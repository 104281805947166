function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => { 
    images[item.replace('./', '').replace(/\.(png|jpe?g|svg)$/, '')] = r(item); 
  });
  return images;
}

const tileImages = importAll(require.context('../tiles/', false, /\.(png|jpe?g|svg)$/));

console.log('Available tiles:', Object.keys(tileImages));

export default tileImages;