import React from 'react';
import '../styles/Controller.css';
import upArrow from '../assets/up-arrow.png';
import downArrow from '../assets/down-arrow.png';
import leftArrow from '../assets/left-arrow.png';
import rightArrow from '../assets/right-arrow.png';

const Controller = ({ onMove }) => {
  return (
    <div className="controller">
      <button className="control-btn up" onClick={() => onMove('ArrowUp')}>
        <img src={upArrow} alt="Up" />
      </button>
      <div className="horizontal-buttons">
        <button className="control-btn left" onClick={() => onMove('ArrowLeft')}>
          <img src={leftArrow} alt="Left" />
        </button>
        <button className="control-btn down" onClick={() => onMove('ArrowDown')}>
          <img src={downArrow} alt="Down" />
        </button>
        <button className="control-btn right" onClick={() => onMove('ArrowRight')}>
          <img src={rightArrow} alt="Right" />
        </button>
      </div>
    </div>
  );
};

export default Controller;