import React, { useState } from 'react';
import tileImages from '../utils/tileUtils';
import editButtonImage from '../assets/edit_button.png';
import mainMapImage from '../assets/main-map.png';
import '../styles/LevelEditor.css';

const LevelEditor = ({ level, onUpdate }) => {
  const [editingLevel, setEditingLevel] = useState(level);
  const [selectedTile, setSelectedTile] = useState(Object.keys(tileImages)[0]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCellClick = (rowIndex, colIndex) => {
    // Handle tile placement logic here if needed
  };

  return (
    <div className="level-editor">
      <div className="editor-controls">
        <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="edit-button">
          <img src={editButtonImage} alt="Edit" />
        </button>
        {isMenuOpen && (
          <div className="editor-menu">
            <div className="tile-selector">
              {Object.entries(tileImages).map(([tileName, tileImage]) => (
                <button 
                  key={tileName} 
                  onClick={() => setSelectedTile(tileName)}
                  className={`tile-button ${selectedTile === tileName ? 'selected' : ''}`}
                >
                  <img src={tileImage} alt={tileName} />
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="canvas">
        <div 
          className="grid"
          style={{ 
            backgroundImage: `url(${mainMapImage})`,
            backgroundSize: 'cover',
            gridTemplateColumns: `repeat(${editingLevel.cols}, 1fr)`,
            gridTemplateRows: `repeat(${editingLevel.rows}, 1fr)`,
          }}
        >
          {editingLevel.tileGrid.map((row, rowIndex) =>
            row.map((cell, colIndex) => (
              <div
                key={`${rowIndex}-${colIndex}`}
                className="cell"
                onClick={() => handleCellClick(rowIndex, colIndex)}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default LevelEditor;