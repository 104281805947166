import React, { useState, useEffect, useRef, useCallback } from 'react';
import { level1CollisionMap, TILE_TYPES } from '../collisionmaps';
import mainMapImage from '../assets/main-map.png';
import rheedImage from '../assets/rheed.png';
import '../styles/Gameplay.css';
import Controller from './Controller';
import { REACT_APP_ENABLE_DEV_MODE } from '../config'; // Assuming you have a config file

// Define the constants at the top of your file
const MAP_WIDTH = 800;  // Adjust this value to match your map width
const MAP_HEIGHT = 600; // Adjust this value to match your map height
const TILE_SIZE = 32;   // Adjust this value to match your tile size

const DOOR_INFO = {
  [TILE_TYPES.DOOR_1]: {
    url: 'https://www.twitch.com/mrrheed',
    message: 'Do you want to visit Rheed\'s Twitch?'
  },
  [TILE_TYPES.DOOR_2]: {
    url: 'https://www.youtube.com/@rheedcraft',
    message: 'Do you want to visit Rheed\'s Minecraft YouTube?',
    style: { color: 'black' }
  }
};

const Gameplay = () => {
  const gridSize = 112;
  const gridWidth = 20;
  const gridHeight = 10;

  const [playerPosition, setPlayerPosition] = useState({ x: Math.floor(gridWidth / 2), y: Math.floor(gridHeight / 2) });
  const [cameraOffset, setCameraOffset] = useState({ x: 0, y: 0 });
  const [collisionMap, setCollisionMap] = useState(level1CollisionMap[0]);
  const [isDrawingCollision, setIsDrawingCollision] = useState(false);
  const [devMode, setDevMode] = useState(process.env.NODE_ENV !== 'production' && process.env.REACT_APP_ENABLE_DEV_MODE === 'true');
  const [collisionOpacity, setCollisionOpacity] = useState(0.3);
  const [hoverCell, setHoverCell] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pendingDoorInfo, setPendingDoorInfo] = useState(null);

  const gameCanvasRef = useRef(null);

  const toggleCollision = useCallback((x, y) => {
    setCollisionMap(prevMap => {
      const newMap = prevMap.map(row => [...row]);
      if (y >= 0 && y < newMap.length && x >= 0 && x < newMap[y].length) {
        newMap[y][x] = !newMap[y][x];
      }
      return newMap;
    });
  }, []);

  const handleMouseDown = useCallback((e) => {
    if (!devMode || !isDrawingCollision) return;
    const rect = gameCanvasRef.current.getBoundingClientRect();
    const x = Math.floor((e.clientX - rect.left) / gridSize);
    const y = Math.floor((e.clientY - rect.top) / gridSize);
    toggleCollision(x, y);
  }, [devMode, isDrawingCollision, gridSize]);

  const handleMouseMove = useCallback((e) => {
    if (!devMode) return;
    const rect = gameCanvasRef.current.getBoundingClientRect();
    const x = Math.floor((e.clientX - rect.left) / gridSize);
    const y = Math.floor((e.clientY - rect.top) / gridSize);
    setHoverCell({ x, y });

    if (isDrawingCollision && e.buttons === 1) {
      toggleCollision(x, y);
    }
  }, [devMode, isDrawingCollision, gridSize]);

  const handleMouseLeave = useCallback(() => {
    setHoverCell(null);
  }, []);

  const exportCollisionMap = () => {
    const formattedMap = collisionMap.map(row => 
      `        [${row.map(cell => cell ? 1 : 0).join(',')}]`
    ).join(',\n');

    const exportString = `export const levelCollisionMap = [\n    [\n${formattedMap}\n    ]\n];`;
    console.log(exportString);
    // You can also copy to clipboard or download as a file here
  };

  // Function to update collisionMaps.js (this is a placeholder)
  const updateCollisionMapFile = (level, mapData) => {
    console.log(`Updating level ${level} collision map:`);
    console.log(mapData);
    // In a real implementation, you'd send this data to your server
    // to update the collisionMaps.js file
  };

  const handleMove = (direction) => {
    setPlayerPosition(prev => {
      let newX = prev.x;
      let newY = prev.y;
      switch (direction) {
        case 'ArrowUp':
        case 'w':
        case 'W':
          newY = Math.max(prev.y - 1, 0);
          break;
        case 'ArrowDown':
        case 's':
        case 'S':
          newY = Math.min(prev.y + 1, gridHeight - 1);
          break;
        case 'ArrowLeft':
        case 'a':
        case 'A':
          newX = Math.max(prev.x - 1, 0);
          break;
        case 'ArrowRight':
        case 'd':
        case 'D':
          newX = Math.min(prev.x + 1, gridWidth - 1);
          break;
        default:
          return prev;
      }

      // Safety check to ensure collisionMap is defined and has the expected structure
      if (!collisionMap || !Array.isArray(collisionMap) || !collisionMap[newY] || !Array.isArray(collisionMap[newY])) {
        console.error('Invalid collisionMap structure:', collisionMap);
        return prev; // Don't move if the collisionMap is invalid
      }

      const tileType = collisionMap[newY][newX];

      if (tileType === TILE_TYPES.SOLID) {
        return prev; // Collision detected, don't move
      }

      // Allow movement to the new position
      const newPosition = { x: newX, y: newY };

      // Check if the new position is a door tile
      if (tileType === TILE_TYPES.DOOR_1 || tileType === TILE_TYPES.DOOR_2) {
        setShowConfirmation(true);
        setPendingDoorInfo(DOOR_INFO[tileType]);
      }

      return newPosition;
    });
  };

  const handleConfirm = () => {
    if (pendingDoorInfo) {
      window.location.href = pendingDoorInfo.url;
    }
  };

  const handleCancel = () => {
    setShowConfirmation(false);
    setPendingDoorInfo(null);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      const validKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'w', 'a', 's', 'd', 'W', 'A', 'S', 'D'];
      if (validKeys.includes(e.key)) {
        e.preventDefault(); // Prevent default scrolling behavior
        handleMove(e.key);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const updateCameraOffset = () => {
      const canvasWidth = gridWidth * gridSize;
      const canvasHeight = gridHeight * gridSize;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let newOffsetX = Math.max(Math.min(0, viewportWidth / 2 - playerPosition.x * gridSize), viewportWidth - canvasWidth);
      let newOffsetY = Math.max(Math.min(0, viewportHeight / 2 - playerPosition.y * gridSize), viewportHeight - canvasHeight);

      setCameraOffset({ x: newOffsetX, y: newOffsetY });
    };

    updateCameraOffset();
  }, [playerPosition]);

  useEffect(() => {
    // Load the appropriate collision map when the level changes
    const collisionMaps = {
      1: level1CollisionMap,
      // Add more levels as needed
    };
    setCollisionMap(collisionMaps[currentLevel][0]); // Note the [0] here
  }, [currentLevel]);

  useEffect(() => {
    console.log('collisionMap updated:', collisionMap);
  }, [collisionMap]);

  useEffect(() => {
    // Remove the ability to toggle dev mode
    // const handleKeyPress = (e) => {
    //   if (e.key === '`') {
    //     setDevMode(prevMode => !prevMode);
    //   }
    // };

    // window.addEventListener('keydown', handleKeyPress);
    // return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <div className="gameplay-wrapper">
      <div 
        className="gameplay"
        style={{
          transform: `translate(${cameraOffset.x}px, ${cameraOffset.y}px)`,
          transition: 'transform 0.3s ease-out',
        }}
      >
        <div 
          ref={gameCanvasRef}
          className="game-canvas"
          style={{ 
            backgroundImage: `url(${mainMapImage})`,
            backgroundSize: `${gridWidth * gridSize}px ${gridHeight * gridSize}px`,
            width: `${gridWidth * gridSize}px`,
            height: `${gridHeight * gridSize}px`,
            position: 'relative',
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <div 
            className="player"
            style={{
              backgroundImage: `url(${rheedImage})`,
              left: `${playerPosition.x * gridSize}px`,
              top: `${playerPosition.y * gridSize}px`,
              width: `${gridSize}px`,
              height: `${gridSize}px`,
              position: 'absolute',
            }}
          />
          {devMode && (
            <>
              <div className="collision-map" style={{
                position: 'absolute',
                top: 0,
                left: 0,
                pointerEvents: 'none',
              }}>
                {collisionMap.map((row, y) => 
                  row.map((cell, x) => 
                    cell === 1 && (
                      <div 
                        key={`${x}-${y}`}
                        style={{
                          position: 'absolute',
                          left: `${x * gridSize}px`,
                          top: `${y * gridSize}px`,
                          width: `${gridSize}px`,
                          height: `${gridSize}px`,
                          backgroundColor: `rgba(255, 0, 0, ${collisionOpacity})`,
                        }}
                      />
                    )
                  )
                )}
              </div>
              {isDrawingCollision && hoverCell && (
                <div 
                  style={{
                    position: 'absolute',
                    left: `${hoverCell.x * gridSize}px`,
                    top: `${hoverCell.y * gridSize}px`,
                    width: `${gridSize}px`,
                    height: `${gridSize}px`,
                    border: '2px solid red',
                    boxSizing: 'border-box',
                    pointerEvents: 'none',
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="controls-overlay">
        <Controller onMove={handleMove} />
      </div>
      {devMode && (
        <div className="dev-controls" style={{
          position: 'fixed',
          top: '10px',
          left: '10px',
          backgroundColor: 'rgba(0,0,0,0.7)',
          padding: '10px',
          borderRadius: '5px',
          color: 'white',
        }}>
          <button 
            onClick={() => setIsDrawingCollision(!isDrawingCollision)}
            style={{
              backgroundColor: isDrawingCollision ? 'green' : 'red',
              color: 'white',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '3px',
              cursor: 'pointer',
            }}
          >
            {isDrawingCollision ? 'Stop Setting Collision' : 'Set Collision'}
          </button>
          <button onClick={exportCollisionMap} style={{marginLeft: '10px'}}>
            Export Collision Map
          </button>
          <div style={{marginTop: '10px'}}>
            <label>
              Collision Opacity:
              <input 
                type="range" 
                min="0" 
                max="1" 
                step="0.1" 
                value={collisionOpacity}
                onChange={(e) => setCollisionOpacity(parseFloat(e.target.value))}
              />
            </label>
          </div>
          <div style={{marginTop: '10px'}}>
            <label>
              Level:
              <select 
                value={currentLevel} 
                onChange={(e) => setCurrentLevel(Number(e.target.value))}
              >
                <option value={1}>Level 1</option>
                <option value={2}>Level 2</option>
                {/* Add more options as needed */}
              </select>
            </label>
          </div>
        </div>
      )}
      {showConfirmation && pendingDoorInfo && (
        <div className="confirmation-dialog">
          <p>{pendingDoorInfo.message}</p>
          <button onClick={handleConfirm}>Yes</button>
          <button onClick={handleCancel}>No</button>
        </div>
      )}
      {devMode && (
        <div>Dev Mode: {devMode ? 'ON' : 'OFF'}</div>
      )}
    </div>
  );
};

export default Gameplay;
