import React, { useState } from 'react';
import Gameplay from './components/Gameplay';
import LevelEditor from './components/LevelEditor';
import './App.css';

function App() {
  const [isEditing, setIsEditing] = useState(false);
  const [devMode, setDevMode] = useState(false);

  return (
    <div className="App">
      {devMode && (
        <>
          <h1>My Grid Game</h1>
          <button onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Exit Editor' : 'Enter Editor'}
          </button>
        </>
      )}
      {isEditing ? <LevelEditor /> : <Gameplay setDevMode={setDevMode} />}
    </div>
  );
}

export default App;